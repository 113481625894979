import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchBankDetailLikeDto } from "../../service/BankDetailRequest";

const index = 1;
const pageSize = 10;

export default function BankDetailIdSelect({ setBankDetailIdChange, bank }) {
  const [bankDetailText, setBankDetailText] = useState("");
  const [data, setData] = useState([]);
  const bankDetailTextChange = (event, bankDetailTextValue) => {
    console.log("bankDetailTextValue===", bankDetailTextValue);
    if (bankDetailTextValue) {
      initBankDetailData(index, pageSize, bankDetailTextValue);
    }
  };
  const bankDetailTextOnChange = (event, newBankDetailText, detail) => {
    console.log("newBankDetailText===", newBankDetailText);
    setBankDetailText(newBankDetailText);
    setBankDetailIdChange(newBankDetailText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (bank) {
      setBankDetailText(bank);
    }
    initBankDetailData(index, pageSize, "");
  }, []);

  const initBankDetailData = async (index, pageSize, searchText) => {
    try {
      
      let bankDetailDataResponse = await searchBankDetailLikeDto(
        index,
        pageSize,
        searchText,
      );

      console.log("bankDetailDataResponse===", bankDetailDataResponse);
      let responseCode = bankDetailDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = bankDetailDataResponse.data;
        setData(dataRecord);
      }
    } catch (e) {
      console.log("initBankDetailData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.bankName + " / " + option.bankAccount;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Bank Detail info"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, bankDetailTextValue) =>
          bankDetailTextChange(event, bankDetailTextValue)
        }
        value={bankDetailText}
        onChange={(event, newBankDetailText, detail) => {
          bankDetailTextOnChange(event, newBankDetailText, detail);
        }}
      />
    </Stack>
  );
}
