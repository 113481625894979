import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import UserEditForm from "./UserEditForm";
import UserForm from "./UserForm";
import { pageUserDto, searchUserDto,deleteUser } from "../../service/UserRequest";
import { useForm, Controller } from "react-hook-form";

import BusinessIdSelect from "../common/BusinessIdSelect";
import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Grid,
  makeStyles,
  Input,
} from "@material-ui/core";

import { UserAddButtonControl } from "./style/UserStyle";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function UserTable() {

  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editUserDto, setEditUserDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [id,setId] = useState();
  const [firstNameLike,setFirstNameLike] = useState();
  const [businessId,setBusinessId] = useState();
  const [deleteOpen,setDeleteOpen] = useState(false);
  const [firstName,setFirstName] = useState();

  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      id: '',
      firstNameLike:'',
  
    }

    
  });

  const searchUser = () => {
    console.log("======searchUser", searchName);
    setSearchRefreshFlag(true);
  };

  const addUser = () => {
    console.log("======handle add addUser");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);
 

    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const handleSubmitAddUser = () => {
    
    console.log("==handleSubmitAddUser====");

    setAddOpen(!addOpen);
    let userQueryVo = {};
    initData(userQueryVo);
  };

  const handleAddClose = () => {
  
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
    
    console.log("===page", page);
    page = page + 1;
    initData(page, 10);
  };

  const editUserData = (tableMeta) => {
    
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editUserDto = data[row];
    setEditUserDto(editUserDto);
  };


  const deleteUserData = (tableMeta) => {
    
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setDeleteOpen(!deleteOpen);
    const editUserDto = data[row];
    setFirstName(editUserDto.firstName);
    setId(editUserDto.id);
  
  };
 
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = async (userQueryVo) => {


    try{


      let userQueryVoData= {};

      Object.keys(userQueryVo).map((key) => {
        if(userQueryVo[key]) {
          userQueryVoData[key] = userQueryVo[key];
          
        }
      });


      setIsLoading(true);
  

      let initDataUserResponse = await  pageUserDto(userQueryVoData);
  
      console.log("initDataUserResponse====",initDataUserResponse.data);
  
      if(initDataUserResponse && initDataUserResponse.data){
        setData(initDataUserResponse.data.records);
        setCount(initDataUserResponse.data.total);
      }
      setIsLoading(false);
  
      setPage(userQueryVo.index);
    }catch(e){
      console.log("initData===error",e);
    }
   
  };

  const searchUserData = (index, pageSize, searchName) => {
   
    let userQueryVo = {
      index,
      pageSize,
      searchName,
    };
    initData(userQueryVo);
  };


  const onSubmit = (data) =>{
    

     


    let userQueryVo = {
      index:1,
      pageSize:10,
      id:data.id != null ? data.id : null,
      firstNameLike:data.firstNameLike != null ? data.firstNameLike : null,
      businessId:businessId != null ? businessId+'' : null,
    }

  
    initData(userQueryVo);
    
  }


  const userReset = () => {
    reset();
    setFirstNameLike();
    setBusinessId();
   
  }

  const setBusinessIdChange = (businessText)=> {
 
    console.log("setBusinessIdChang==businessText",businessText);
    if (businessText) {
      setBusinessId(businessText.id);
    }
  
  }


  const handleDeleteClose = () => {
    setDeleteOpen(!deleteOpen);
  }

  const deleteUserDto = async ()=>{
    try{

      let userDto = {
        id:id,
      };
      let deleteUserDtoResponse = await deleteUser(userDto);

      
      setDeleteOpen(!deleteOpen);

      let userQueryVo = {
        index:1,
        pageSize:10,
      }
  
    
      initData(userQueryVo);
    }catch(e){
      console.log("deleteUserDto===error",e);
    }
    setFirstName();
    setId();

  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API

    initData(page, 10);
  }, [refreshFlag]);

  useEffect(() => {
  
    console.log("===searchUserData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    if (!searchName) return;
    searchUserData(page, 10, searchName);

    setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            return value.toString();
          },
        },
      },

      {
        name: "type",
        label: "Type",
        options: {},
      },
      {
        name: "firstName",
        label: "First Name",
        options: {},
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {},
      },
      {
        name: "businessName",
        label: "Company Name",
        options: {},
      },
      {
        name: "foreignName",
        label: "Foreign name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            
            console.dir(tableMeta);
            return value;
          },
        },
      },
      {
        name: "phoneNumber",
        label: "Phone Number",
        options: {},
      },
      {
        name: "emailAddress",
        label: "Email Address",
        options: {},
      },
      {
        name: "loginPassword",
        label: "Login Password",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
                 <Stack spacing={2} >
                  <Item elevation={0}> 
                    <Stack direction="row" spacing={2}>
                      <Item elevation={0}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={(e) => editUserData(tableMeta)}
                        >
                        Edit
                        </Button>
                      </Item>
                      <Item elevation={0}>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={(e) => deleteUserData(tableMeta)}
                          >
                          Delete
                          </Button>
                        </Item>
                    </Stack>
                  </Item>
                 </Stack>
              
              </Box>
            );
          },
        },
      },
    ],
  };

  return (
    <div>
      
        {/* <TextField
          id="firstName"
          label="First Name"
          value={searchName}
          variant="standard"
          onChange={(e) => setSearchName(e.target.value)}
        /> */}

        <form >
        <Grid container spacing={3}>


        <Grid item xs={4}>
          <Paper className={classes.paper}>Id:
            <Controller
            name="id"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>


        <Grid item xs={4}>
          <Paper className={classes.paper}>FirstName:
            <Controller
            name="firstNameLike"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
         </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper className={classes.paper}> 
          <BusinessIdSelect
                          setBusinessIdChange={setBusinessIdChange}
                          
                        />
         </Paper>
        </Grid>

       

        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={handleSubmit(onSubmit)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => userReset()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>
        <Grid item xs={4}>
        <Paper className={classes.paper}><Button onClick={() => addUser()} variant={"outlined"} color="primary">Add</Button></Paper>
        </Grid>
        </Grid>

        </form>
       
 
{/* 
      <UserAddButtonControl>
        <Button
          className="btn right"
          variant="outlined"
          onClick={searchUser}
          color="primary"
        >
          Search
        </Button>
        <Button
          className="btn right"
          variant="outlined"
          onClick={addUser}
          color="primary"
        >
          Add
        </Button>
      </UserAddButtonControl> */}

      <MUIDataTable
        title={
          <Typography variant="h6">
            User list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit user</DialogTitle>
        <DialogContent>
          <UserEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editUserDto={editUserDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addOpen} onClose={handleAddClose} fullWidth maxWidth="lg"  PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
        <UserForm
              handleSubmit={handleSubmitAddUser}
              handleAddClose={handleAddClose}
            />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>


      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">
      Are you sure you want to delete this   
        </DialogTitle>
        <DialogContent>

          <Typography variant="subtitle1" gutterBottom>
            {firstName}
          </Typography>
          
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>NO</Button>
          <Button onClick={deleteUserDto} autoFocus>
            YES
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
