import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
import { searchUserLikeDto } from "../../service/UserRequest";

const index = 1;
const pageSize = 10;

export default function UserIdSelect({ setUserIdChange, user,labelText,filterUserType,clearUserId }) {
  const [userText, setUserText] = useState("");
  const [data, setData] = useState([]);
  const userTextChange = (event, userTextValue) => {
    console.log("userTextValue===", userTextValue);
    
    if (userTextValue) {
      let userQueryVo = {
        index:index,
        pageSize:pageSize,
        searchText: userTextValue ? userTextValue : null,
        type: filterUserType ? filterUserType : null
      };
      initUserData(userQueryVo);
    }
    clearUserId();
  };
  const userTextOnChange = (event, newUserText, detail) => {
    console.log("newUserText===", newUserText);
    setUserText(newUserText);
    setUserIdChange(newUserText);
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (user) {
      setUserText(user);
    }

    let userQueryVo = {
      index:index,
      pageSize:pageSize,
      searchText: null,
      type: filterUserType ? filterUserType : null
    };
    initUserData(userQueryVo);
  }, []);

  const initUserData = async (userQueryVo) => {
    try {
     
      
      let userDataResponse = await searchUserLikeDto(
        userQueryVo
      );

      console.log("userDataResponse===", userDataResponse);
      let responseCode = userDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        let dataRecord = userDataResponse.data;

        if(dataRecord){
          setData(dataRecord);
        }
        
      }
    } catch (e) {
      console.log("initUserData==", e);
    }
  };
  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={data}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.firstName;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={labelText}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onInputChange={(event, userTextValue) =>
          userTextChange(event, userTextValue)
        }
        value={userText}
        onChange={(event, newUserText, detail) => {
          userTextOnChange(event, newUserText, detail);
        }}
      />
    </Stack>
  );
}
