import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import StoreAcquirerStoreTable from "./StoreAcquirerStoreTable";
import StoreDeviceTokenTable from "./StoreDeviceTokenTable";
import StoreEditForm from "./StoreEditForm";
import StoreForm from "./StoreForm";
import {
  pageStoreDto,
  searchStoreDto,
  registerStoreAlipayDto,
  alipayInquiryMerchantRegistrationStatus,
  generateStoreAlipayStaticQrcodeImage,
  selectStoreDtoExport,
  selectAcquireStoreDtoExport,
  generateStoreWechatH5QrcodeImage,
} from "../../service/StoreRequest";
import { StoreAddButtonControl } from "./style/StoreStyle";

import {
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Paper,
  Box,
  Grid,
  makeStyles,
  Input,
  Modal,
} from "@material-ui/core";
import Stack from '@mui/material/Stack';

import { useForm, Controller,register } from "react-hook-form";
import {ERROR_CODE_SUCCESS} from "../../components/common/constant/ResponseErrorCodeConstant";
import { ContentPasteSearchRounded } from "@mui/icons-material";
import QRCode from 'react-qr-code';
import  StaticQrcode from "./StaticQrcode";
import {generateString} from "../../components/common/utils/CommonUtils";
import {ALIPAY_STATIC_TITLE,WECHAT_STATIC_TITLE,WECHAT_H5_TITLE,WECHAT_H5_WARNING_MESSAGE} from "../../components/common/constant/ManageConstant";
import { styled } from '@mui/material/styles';

import StoreDcsConfigurationTable from "../storeDcsConfiguration/StoreDcsConfiguration"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function StoreTable() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([["Loading Data..."]]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState(0);
  const [open, setOpen] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [editStoreDto, setEditStoreDto] = useState({});
  const [editDeviceTokenDto, setEditDeviceTokenDto] = useState({});
  const [editAcquirerStoreDto, setEditAcquirerStoreDto] = useState({});

  const [refreshFlag, setRefreshFlag] = useState(false);

  const [searchName, setSearchName] = useState("");

  const [searchRefreshFlag, setSearchRefreshFlag] = useState(false);

  const [deviceTokenOpen, setDeviceTokenOpen] = useState(false);

  const [acquirerStoreOpen, setAcquirerStoreOpen] = useState(false);

  const [storeId, setStoreId] = useState(0);

  const [id,setId] = useState();
  const [storeNameLike, setStoreNameLike] = useState();
  const [businessNameLike, setBusinessNameLike] = useState();
  const [alipayStaticOpen,setAlipayStaticOpen] = useState(false);

  const [alipayStaticQrcode,setAlipayStaticQrcode] = useState();

  const [staticTitle,setStaticTitle] = useState();
  const [warningMessage,setWarningMessage] = useState();
  const [storeNameRowChosen,setStoreNameRowChosen] = useState();

  const [storeDcsConfigurationOpen, setStoreDcsConfigurationOpen] = useState(false);

  const searchStore = () => {
    console.log("======searchStore", searchName);
    setSearchRefreshFlag(true);
  };

  const addStore = () => {
    console.log("======handle add addStore");
    setAddOpen(!addOpen);
  };

  const handleClose = () => {
    console.log("======handle close");
    setOpen(!open);
  };

  const handleEditSubmit = () => {
    console.log("======handle edit submit");
    setOpen(!open);

    setTimeout(() => {
      setRefreshFlag(true);
    }, 10);
  };

  const storeHandleSubmit = () => {
    console.log("==storeHandleSubmit=");

    setAddOpen(!addOpen);

    setRefreshFlag(true);
  };

  const handleAddClose = () => {
    console.log("======handle add close");
    setAddOpen(!addOpen);
    setTimeout(() => {
      setRefreshFlag(true);
    }, 1000);
  };

  const changePage = (page, sortOrder) => {
    setIsLoading(true);
   
    console.log("===page", page);
    page = page + 1;
    let storeQueryVo = {
      id:id,
      storeNameLike:storeNameLike,
      businessNameLike:businessNameLike,
      index:page, 
      pageSize:10,
  }
    initData(storeQueryVo);
  };

  const editStoreData = (tableMeta) => {
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setOpen(!open);
    const editStoreDto = data[row];
    setEditStoreDto(editStoreDto);
  };

  const editDeviceTokenData = (tableMeta) => {
    
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setDeviceTokenOpen(!deviceTokenOpen);
    const editDeviceTokenDto = data[row];
    setStoreId(editDeviceTokenDto.id);
    setEditDeviceTokenDto(editDeviceTokenDto);
    setStoreNameRowChosen(editDeviceTokenDto.name);
    
  };

  const editAcquirerStoreData = (tableMeta) => {
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setAcquirerStoreOpen(!acquirerStoreOpen);
    const editAcquirerStoreDto = data[row];
    setStoreId(editAcquirerStoreDto.id);
    setEditAcquirerStoreDto(editAcquirerStoreDto);
    setStoreNameRowChosen(editAcquirerStoreDto.name);
  };

  const handleDeviceTokenClose = () => {
    console.log("======handle close");
    setDeviceTokenOpen(!deviceTokenOpen);
  };

  const handleAcquirerStoreClose = () => {
    console.log("======handle close");
    setAcquirerStoreOpen(!acquirerStoreOpen);
  };

  const registerStoreAlipay = async (tableMeta) => {
    const row = tableMeta.rowIndex;
    console.log("row", row);

    const recordData = data[row];
   
    console.log("recordData===", recordData);
    console.log("recordData.businessId===", recordData.businessId);
    let businessVo = {
      id: recordData.businessId,
      storeId: recordData.id,
    };
    let registerStoreResponse = await registerStoreAlipayDto(businessVo);
    console.log("registerStoreResponse===", registerStoreResponse);
  };


  const alipayStaticHandleClose = async () => {
      setAlipayStaticOpen(!alipayStaticOpen);
  }

  const handleStoreDcsConfigurationClose = () => {
    console.log("======handleStoreDcsConfigurationClose close");
    setStoreDcsConfigurationOpen(!storeDcsConfigurationOpen);
  };

  const handleStoreDcsConfigurationOpen = (tableMeta) => {
    const row = tableMeta.rowIndex;
    console.log("row", row);
    setStoreDcsConfigurationOpen(!storeDcsConfigurationOpen);
 
  
  };
  const options = {
    filterType: "dropdown",
    responsive: "vertical",
    serverSide: true,
    count: count,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    sortOrder: { sortOrder },
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

      switch (action) {
        case "changePage":
          changePage(tableState.page, tableState.sortOrder);
          break;
        case "sort":
          this.sort(tableState.page, tableState.sortOrder);
          break;
        default:
          console.log("action not handled.");
      }
    },
  };

  const initData = async (storeQueryVo) => {
    setIsLoading(true);

    try{

      console.log("=storeQueryVo====",storeQueryVo)
       
      let storeResponse = await pageStoreDto(storeQueryVo);

      console.log("initData====storeResponse==",storeResponse)
      let code = storeResponse.code;
      
      let data = storeResponse.data;
      if(ERROR_CODE_SUCCESS==code){
        if (data) {
          setData(data.records);
          setCount(data.total);
        }
      }else{
        console.log("initData===load failed");
      }

      setPage(storeQueryVo.index);
    }catch(e){
      console.log("initData====error==",e)
    }
    
    setIsLoading(false);
  };


  const acquireExcelUploadFile = async (data)=>{
    console.log("acquireExcelUploadFile==data==",data);
   
  }
 
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let storeNameQueryVo = {
      id:id,
      storeNameLike:storeNameLike,
      businessNameLike:businessNameLike,
      index:page, 
      pageSize:10,
  }
    initData(storeNameQueryVo);
    setRefreshFlag(false);
  }, [refreshFlag]);

  useEffect(() => {
    console.log("===searchStoreData=");
    if (searchRefreshFlag) {
      console.log("searchName===", searchName);
    }

    let storeNameQueryVo = {
      id:id,
      storeNameLike:storeNameLike,
      businessNameLike:businessNameLike,
      index:page, 
      pageSize:10,
  }
  initData(storeNameQueryVo);

  setSearchRefreshFlag(false);
  }, [searchRefreshFlag]);

  const configurationData = {
    page: 0,
    count: 1,
    rowsPerPage: 10,
    sortOrder: {},
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "id",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            if (value) {
              return value.toString();
            }
            return "";
          },
        },
      },

      {
        name: "name",
        label: "Store Name",
        options: {},
      },
      {
        name: "businessName",
        label: "Company Name",
        options: {},
      },
      {
        name: "registerStatus",
        label: "Alipay register status",
        options: {},
      },
      {
        name: "status",
        label: "Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.

            console.log("customBodyRender");
            console.dir(tableMeta);
            return value;
          },
        },
      },
      {
        name: "storeDescription",
        label: "Store Description",
        options: {},
      },
      {
        name: "voidPassword",
        label: "Void Password",
        options: {},
      },
      {
        name: "creationTime",
        label: "Create Time",
        options: {},
      },
      {
        name: "Action",
        options: {
          filter: true,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Box>
              <Stack spacing={2} >
                    <Item elevation={0}> 
                      <Stack direction="row" spacing={2}>
                        <Item elevation={0}>
                              <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={(e) => editStoreData(tableMeta)}
                            >
                            Edit
                          </Button>
                      </Item>
                        <Item elevation={0}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={(e) => editDeviceTokenData(tableMeta)}
                        >
                          moblie password
                </Button>
                        </Item>
                        <Item elevation={0}>

                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={(e) => editAcquirerStoreData(tableMeta)}
                        >
                  Acquirer Store
                </Button>
                        </Item>
                    </Stack>
                  </Item>
                  <Item elevation={0}>
                        <Stack direction="row" spacing={2}>
                          <Item elevation={0}>
                          <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={(e) => registerStoreAlipay(tableMeta)}
                      >
                        Alipay Register
                      </Button>
                    </Item>
                    <Item elevation={0}>
                            <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={(e) => storeAlipayQuery(tableMeta)}
                        >
                          Alipay query
                        </Button>
                    </Item>
                    <Item elevation={0}>
                            <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={(e) => alipayStaticImageQrcode(tableMeta)}
                        >
                          Alipay static 
                        </Button>
                    </Item>
              </Stack>
              </Item>
                <Item elevation={0}>
                  <Stack direction="row" spacing={2}>
                    <Item elevation={0}>
                          <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={(e) => wechatH5ImageQrcode(tableMeta)}
                      >
                        Wechat h5 
                      </Button>
                    </Item>
                    {/* <Item elevation={0}>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={(e) => handleStoreDcsConfigurationOpen(tableMeta)}
                      >
                        Dcs confinguration
                      </Button>
                    </Item>
                   */}
                  </Stack>
              </Item>
            </Stack>
            </Box>
            );
          },
        },
      },
    ],
  };

  const { control, handleSubmit,reset } = useForm({
    defaultValues: {
      id: "",
      storeName:"",
      businessName:"", 
       
    }

    
  });

  const uploadJeripayStoreFlagExcel = async (e) => {
    

    console.log(e.target.files[0]);

    try{

      let formData = new FormData();
      formData.append('file', e.target.files[0]);

      importStoreExcel(formData);
   
    }catch(e){
      console.log("uploadBusinessExcel===error",e);
    }
  };

  const storeSubmitSearch = (data)=>{
     console.log("storeSubmitSearch",data);
    
    let id = data.id   ? data.id : null;
    let storeNameLikeString = data.storeName || data.storeName.trim() ? data.storeName : null;
    let businessNameLikeString = data.businessName || data.businessName.trim() ? data.businessName : null;

    setId(data.id);
    setStoreNameLike(storeNameLikeString);
    setBusinessNameLike(businessNameLikeString);
    let storeQueryVo = {
        id:id,
        storeNameLike:storeNameLikeString,
        businessNameLike:businessNameLikeString,
        index:1, 
        pageSize:10,
    }
    initData(storeQueryVo);

  }

  const storeResetSearch = () => {
    reset();
    setId();
    setStoreNameLike();
    setBusinessNameLike();
     
  }

  const storeAlipayQuery = async (tableMeta)=>{
    const row = tableMeta.rowIndex;
    console.log("row", row);

    const recordData = data[row];
    
    console.log("recordData===", recordData);
    console.log("recordData.businessId===", recordData.businessId);
    let businessVo = {
      id: recordData.businessId,
      storeId: recordData.id,
    };
    let registerStoreResponse = await alipayInquiryMerchantRegistrationStatus(businessVo);
    console.log("registerStoreResponse===", registerStoreResponse);
  }

  const alipayStaticImageQrcode = async function(tableMeta){

    console.log("alipayStaticImageQrcode===========",tableMeta);
    const row = tableMeta.rowIndex;
    console.log("row", row);

    const recordData = data[row];
    
    console.log("recordData===", recordData);
    console.log("recordData.id===", recordData.id);

    debugger;
    try{
      let storeVo = {
        id: recordData.id,
      };
      let generateStoreResponse = await generateStoreAlipayStaticQrcodeImage(storeVo);

      let code = generateStoreResponse.code;


      if(ERROR_CODE_SUCCESS==code){
        console.log(generateStoreResponse.data.linkUrlQrcode);
        setStaticTitle(ALIPAY_STATIC_TITLE);
        setAlipayStaticOpen(true);
        setAlipayStaticQrcode(generateStoreResponse.data.linkUrl);
      
        
      }else{
        alert("Show static qrcode erro "+generateStoreResponse.message);
      }

      console.log("generateStoreResponse====",generateStoreResponse)
    }catch(e){
      console.log("alipayStaticImageQrcode===error", e);
    }
  }


  const wechatH5ImageQrcode = async function(tableMeta){

    console.log("wechatStaticImageQrcode===========",tableMeta);
    const row = tableMeta.rowIndex;
    console.log("row", row);

    const recordData = data[row];
    
    console.log("recordData===", recordData);
    console.log("recordData.id===", recordData.id);


    try{
      let storeVo = {
        id: recordData.id,
      };
      let generateStoreResponse = await generateStoreWechatH5QrcodeImage(storeVo);

      let code = generateStoreResponse.code;
      

      if(ERROR_CODE_SUCCESS==code){
        setStaticTitle(WECHAT_H5_TITLE);
        console.log(generateStoreResponse.data.linkUrlQrcode);
        setAlipayStaticOpen(true);
        setAlipayStaticQrcode(generateStoreResponse.data.linkUrl);
        setWarningMessage(WECHAT_H5_WARNING_MESSAGE);
      }else{
        alert("Show static qrcode erro "+generateStoreResponse.message);
      }

      console.log("generateStoreResponse====",generateStoreResponse)
    }catch(e){
      console.log("alipayStaticImageQrcode===error", e);
    }
  }



  const exportStoreXls = async(data) => {

     
    console.log("exportStoreXls",data);
    
    let id = data.id   ? data.id : null;
    let storeNameLikeString = data.storeName || data.storeName.trim() ? data.storeName : null;
    let businessNameLikeString = data.businessName || data.businessName.trim() ? data.businessName : null;

    setId(data.id);
    setStoreNameLike(storeNameLikeString);
    setBusinessNameLike(businessNameLikeString);
    let storeQueryVo = {
        id:id,
        storeNameLike:storeNameLikeString,
        businessNameLike:businessNameLikeString,
       
    }
   
    try{
      
      let storeExportDto = await selectStoreDtoExport(storeQueryVo);

       

 
       // 2. Create blob link to download
       const url = window.URL.createObjectURL(new Blob([storeExportDto]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', "store-"+generateString(10)+".xlsx");
       // 3. Append to html page
       document.body.appendChild(link);
       // 4. Force download
       link.click();
       // 5. Clean up and remove the link
       link.parentNode.removeChild(link);
            
    }catch(e){
      console.log("exportStoreXls===error",e);
    }

  }


  const exportAcquirerStoreXls = async(data) => {

     
    console.log("exportStoreXls",data);
    
    let id = data.id   ? data.id : null;
    let storeNameLikeString = data.storeName || data.storeName.trim() ? data.storeName : null;
    let businessNameLikeString = data.businessName || data.businessName.trim() ? data.businessName : null;

    setId(data.id);
    setStoreNameLike(storeNameLikeString);
    setBusinessNameLike(businessNameLikeString);
    let storeQueryVo = {
        id:id,
        storeNameLike:storeNameLikeString,
        businessNameLike:businessNameLikeString,
       
    }
   
    try{
      
      let storeExportDto = await selectAcquireStoreDtoExport(storeQueryVo);

       

 
       // 2. Create blob link to download
       const url = window.URL.createObjectURL(new Blob([storeExportDto]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', "acquierStore-"+generateString(10)+".xlsx");
       // 3. Append to html page
       document.body.appendChild(link);
       // 4. Force download
       link.click();
       // 5. Clean up and remove the link
       link.parentNode.removeChild(link);
            
    }catch(e){
      console.log("exportStoreXls===error",e);
    }

  }

  return (
    <div>
       
       <form >
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
        <Paper className={classes.paper}>Id:
        <Controller
            name="id"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>companyName:
          <Controller
            name="businessName"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
            </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>StoreName:
          <Controller
            name="storeName"
            control={control}
            render={({ field }) => <Input {...field} />}
            />
          </Paper>
        </Grid>
        </Grid>
         
        <Grid container item xs={12} spacing={3}>
       
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          <Button onClick={handleSubmit(storeSubmitSearch)} variant={"outlined"} color="primary">Search</Button></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}><Button onClick={() => storeResetSearch()} variant={"outlined"} color="primary">Reset</Button></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>  <Button onClick={addStore} variant={"outlined"} color="primary">add</Button></Paper>
        </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          <Button onClick={handleSubmit(exportStoreXls)} variant={"outlined"} color="primary">Export store</Button></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
          <Button onClick={handleSubmit(exportAcquirerStoreXls)} variant={"outlined"} color="primary">Export acquire type</Button></Paper>
        </Grid>

        <Grid item xs={4}>
        <Paper className={classes.paper}>
          Upload Jeripay store flag excel
          <input type="file" onChange={uploadJeripayStoreFlagExcel}  />
           </Paper>
        </Grid>
        </Grid>
      </Grid>
      </form>

    
      {/* <form onSubmit={handleSubmit(acquireExcelUploadFile)}>

      <Grid container spacing={1}>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
        <Paper className={classes.paper}>Import acquire excel:
        <input type="file"   />
        <input type="submit" />
            </Paper>
        </Grid>
       
        </Grid>

      </Grid>
      
      </form> */}
      <MUIDataTable
        title={
          <Typography variant="h6">
            Store list
            {isLoading && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </Typography>
        }
        data={data}
        columns={configurationData.columns}
        options={options}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit {editStoreDto.name}</DialogTitle>
        <DialogContent>
          <StoreEditForm
            handleEditClose={handleClose}
            handleEditSubmit={handleEditSubmit}
            editStoreDto={editStoreDto}
          />
        </DialogContent>
      </Dialog>

      <Dialog fullWidth maxWidth="lg" open={addOpen} onClose={handleAddClose}>
        <DialogTitle>Add Store</DialogTitle>
        <DialogContent>
          <Paper>
            <StoreForm
              handleSubmit={storeHandleSubmit}
              handleAddClose={handleAddClose}
            />
          </Paper>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        open={deviceTokenOpen}
        onClose={handleDeviceTokenClose}
        fullWidth
        maxWidth="lg"
      >
      <DialogTitle sx={{ m: 2, p: 5 }}>{storeNameRowChosen}</DialogTitle>
        
        <DialogContent>
        
          <StoreDeviceTokenTable
            handleDeviceTokenEditClose={handleDeviceTokenClose}
            editDeviceTokenDto={editDeviceTokenDto}
            parameterStoreId={storeId}
            storeNameMobilePasswd={storeNameRowChosen}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={acquirerStoreOpen}
        onClose={handleAcquirerStoreClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{ m: 2, p: 5 }}>[{storeNameRowChosen}]  Acquirer Store</DialogTitle>
        <DialogContent>
          <StoreAcquirerStoreTable
            handleAcquirerStoreEditClose={handleAcquirerStoreClose}
            editAcquirerStoreDto={editAcquirerStoreDto}
            parameterStoreId={storeId}
          />
        </DialogContent>
      </Dialog>


      <Dialog
        open={alipayStaticOpen}
        onClose={alipayStaticHandleClose}
        fullWidth
        maxWidth="lg"
      >
      <DialogTitle sx={{ m: 2, p: 5 }}>{staticTitle}</DialogTitle>

      <DialogContent>


        <StaticQrcode linkUrl={alipayStaticQrcode} size={200} staticQrcodeClose={alipayStaticHandleClose} warningMessage={warningMessage}/>
        
      </DialogContent>
   
      </Dialog>


      <Dialog
        open={storeDcsConfigurationOpen}
        onClose={handleStoreDcsConfigurationClose}
        PaperProps={{
          style: {
            width: "100%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Store dcs configuration</DialogTitle>
        <DialogContent>
           <StoreDcsConfigurationTable/>
        </DialogContent>
      </Dialog>
      
    </div>
    
  );
}
