import React, { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { searchBusinessLikeDtoV1 } from "../../service/BusinessRequest";
import { ERROR_CODE_SUCCESS } from "../../components/common/constant/ResponseErrorCodeConstant";
export default function MultiBusinessSelect({businessName,setBusinessIdListChange,clearSelectBusinessId,saveFlag}) {
  const [data, setData] = useState([]);
  const [index,setIndex] = useState(1);
  const [pageSize,setPageSize ]= useState(10);
  const [businessText, setBusinessText] = useState(null);
  const [selectBusinessIdList,setSelectBusinessIdList] = useState([]);

  const businessTextChange = (event, businessTextValue) => {
    console.log("businessTextChange===businessTextValue===", businessTextValue);
    if (businessTextValue) {

      let searchBusinessLikeDto =  {
        index:index, 
        pageSize:pageSize ,
        searchLikeKey:businessTextValue
       }
      initMultiBusinessData(searchBusinessLikeDto);
    }
    // if(clearSelectBusinessId){
    //   clearSelectBusinessId();
    // }
    
  };
  const businessTextOnChange = (event, newBusinessText, detail) => {
    console.log("businessTextOnChange===newBusinessText===", newBusinessText);
    // setBusinessText(newBusinessText);
    // setBusinessIdChange(newBusinessText);
  
    setBusinessIdListChange(newBusinessText);
     
    
  };

  const initMultiBusinessData = async (searchBusinessLikeDto) => {
    
    try {
      let businessDataResponse = await searchBusinessLikeDtoV1(
        searchBusinessLikeDto
      );

      console.log("initMultiBusinessData===", businessDataResponse);
      let responseCode = businessDataResponse.code;
      if (responseCode == ERROR_CODE_SUCCESS) {
        if(businessDataResponse.data){
          let dataRecord = businessDataResponse.data;
          setData(dataRecord);
        }
       
      }
    } catch (e) {
      console.log("initMultiBusinessData==", e);
    }
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (businessName) {
      setBusinessText(businessName);
    }

   let searchBusinessLikeDto =  {
    index:index, 
    pageSize:pageSize 
   }
    initMultiBusinessData(searchBusinessLikeDto);
  }, []);
  return (
    <div>
        <Autocomplete
           multiple
            id="size-small-standard-multi"
            size="small"
            options={data}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                  return option;
                }
                if(option  && option.businessDescription){
                  return option.name + " / " + option.businessDescription;  
                }
                if(option ){
                  return option.name ;
                }  else{
                  return "";
                }
              
          
            }}
           
            renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                label="Company Name"
                placeholder="Comapany Name"
            />
            )}

            onInputChange={(event, businessTextValue) =>
             businessTextChange(event, businessTextValue)
           }
           defaultValue={saveFlag ? [] : []}
            onChange={(event, newBusinessText, detail) => {
              businessTextOnChange(event, newBusinessText, detail);
            }}
        />
    </div>
   
  
      
    
  );
}
 